@import url(https://fonts.googleapis.com/css2?family=Cabin&display=swap);
html,
body {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  background: #314e52;
}
/* flex box to allow editor-preview-container to fill page */
#app-wraper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  height: 98vh;
}
/*------------- styling for the title --------------*/
#page-title {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  min-height: 55px;
  max-height: 7vh;
  text-align: center;
  background: #314e52;
  color: #f2a154;
  font-family: Cabin, Arial, sans-serif;
  letter-spacing: 0.1em;
}
#page-title > * {
  margin: 0;
}
/*----------- Styling for editor-preview-container --------------*/
#editor-preview-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  max-height: 90vh;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  background: #314e52;
  margin-bottom: 1%;
}
#editor-preview-container > * {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  overflow: scroll;
  margin: 0 1% 0 1%;
  border: 7px solid #f2a154;
  border-radius: 2%;
}
#editor {
  width: 40%;
  height: 88.5vh;
  max-height: 88.5vh;
  background: #f7f6e7;
}
#editor:focus {
  width: 70%;
  background: #e7e6e1;
}
#preview {
  padding-left: 3px;
  padding-right: 1px;
  background: #f7f6e7;
  font-family: Cabin, Arial, sans-serif;
  width: 60%;
  height: 89vh;
}
/*-------- change flex direction and relitive sizes on small screens --------------*/
@media (max-width: 900px) {
  #editor-preview-container {
    -webkit-flex: 1 1;
            flex: 1 1;
    background: #314e52;
    margin: 0 1% 1% 1%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  #editor {
    min-width: 95%;
    max-width: 95%;
    min-height: 30vh;
    max-height: 40vh;
  }
  #editor:focus {
    min-width: 95%;
    max-width: 95%;
    min-height: 50vh;
  }
  #preview {
    width: 95%;
    max-height: 60%;
    margin-top: 1%;
  }
}

